<template>
  <div>
    <QueryForm v-model="queryForm" :query-btn-loading="tableLoading" @query="handleQuery" @reset="handleQueryFormReset">
      <el-form-item label="模板应用场景">
        <el-input v-model="queryForm.scenario" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="模板类型">
        <el-input v-model="queryForm.templateName" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
    </QueryForm>

    <Table
      :table-data="tableData"
      max-height="600px"
      :columns="templateTableColumns"
      :loading="tableLoading"
    >
      <el-table-column slot="operate" label="操作" width="100px">
        <template slot-scope="scope">
          <el-button type="text" @click="handleTemplate(scope.row)">编辑模板</el-button>
        </template>
      </el-table-column>
    </Table>
    <WangEdit
      v-model="dialogWangEditVisible"
      :html="currentRow.templateContent"
      :params="currentRow"
      @update="handleQuery"
    />

  </div>
</template>

<script>
import QueryForm from '@/components/QueryForm'
import { templateTableColumns } from '@/constant/tablecolumns'
import { templateList, editTemplate } from '@/api/scm-api'
import Table from '@/components/Table'
import WangEdit from './components/WangEditor'

export default {
  components: { QueryForm, Table, WangEdit },
  data() {
    return {
      templateTableColumns,
      tableData: [],
      dialogWangEditVisible: false,
      queryForm: { },
      currentRow: {},
      tableLoading: false
    }
  },
  computed: {

  },
  mounted() {
    this.handleQuery()
  },
  methods: {
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    async handleQuery() {
      try {
        this.tableLoading = true
        const { datas } = await templateList(this.queryForm)
        this.tableData = datas
      } finally {
        this.tableLoading = false
      }
    },
    async handleTemplate({ id }) {
      const { datas } = await editTemplate(id)
      this.currentRow = datas
      this.dialogWangEditVisible = true
    }

  }
}
</script>

<style>

</style>
