<template>
  <div>
    <el-dialog
      title="编辑模板"
      :visible.sync="dialogVisible"
      fullscreen
      center
      :close-on-click-modal="false"
    >
      <div style="border: 1px solid #ccc; margin-top: 10px">
        <!-- 工具栏 -->
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :default-config="toolbarConfig"
        />
        <!-- 编辑器 -->
        <Editor
          v-model="newHtml"
          style="height: 600px; overflow-y: hidden"
          :default-config="editorConfig"
          @onCreated="onCreated"
        />
      </div>
      <div class="mt-4">特殊说明：编辑框内容仅为模板中部分可编辑文本,点击预览可查看模板完整内容</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="$emit('input',false)">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleWangSubmit('view',templateView)">预览</el-button>
        <el-button type="primary" :loading="loading" @click="handleWangSubmit('save',saveTemplate)">保存</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="模板预览"
      :visible.sync="dialogViewVisible"
      width="80%"
      center
      :close-on-click-modal="false"
      append-to-body
    >
      <p v-html="htmlView" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogViewVisible=false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveTemplate, templateView } from '@/api/scm-api'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
  name: 'EditorVue',
  components: { Editor, Toolbar },
  props: {
    html: {
      type: String,
      default: ''
    },
    readOnlys: { // 只读
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      dialogViewVisible: false,
      htmlView: '',
      loading: false,
      saveTemplate, templateView,
      saveHtml: '',
      editor: null,
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */
        toolbarKeys: [
          'headerSelect',
          // '|',
          'bold',
          'underline',
          'italic',
          'color',
          'bgColor',
          // '|',
          'indent', // 增加缩进
          'delIndent', // 减少缩进
          'justifyLeft', // 左对齐
          'justifyRight', // 右对齐
          'justifyCenter', // 居中
          'justifyJustify', // 两端对齐
          // '|',
          'fontSize',
          'fontFamily',
          'lineHeight',
          // '|',
          'bulletedList',
          'numberedList',
          'todo',
          'insertLink',
          'insertTable',
          // '|',
          'codeBlock',
          'divider',
          'uploadImage',
          'undo',
          'redo'
        ]
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: '请输入内容',
        // autoFocus: false,
        // readOnly: true, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          // 配置上传图片
          uploadImage: {
            customUpload: this.uploaadImg
          }
        }
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    newHtml: {
      get() {
        return this.html
      },
      set(val) {
        // this.$emit('getNewHtml', val)
        this.saveHtml = val
      }
    }
  },
  watch: {

  },
  mounted() {

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor
  },
  methods: {
    async handleWangSubmit(type, api) {
      try {
        this.loading = true
        const params = Object.assign(this.params, { templateContent: this.saveHtml })
        const { datas } = await api(params)
        if (type === 'save') {
          this.$notify({
            message: '操作成功',
            type: 'success'
          })
          this.$emit('input', false)
        } else {
          this.htmlView = datas
          this.dialogViewVisible = true
        }
      } finally {
        this.loading = false
      }
    },
    uploaadImg(file, insertFn) {
      this.$emit('uploadImg', file, insertFn)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor)
    }
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
